// Loader component

import { defer } from "react-router-dom";
import { dataLoader } from '/src/data/dataLoader/dataLoader';

import { 
    getAssetsRent,
    getAssetsLong,
    getAssetsSale,
    getAssetsHotel,
    getAssetsRealState,
    getAssetsRestaurant,
    getAssetsStore,
    getAssetsActivity,
    getAssetsService,
  } from '/src/data/api/assets';

  // import config from "/src/config/config";

// ****************************************************************
// FOR DEVELOPMENT ONLY --> LOAD DATA FROM MOCKS --> FOR DEVELOPMENT ONLY
// ****************************************************************
import assetList from '/src/mocks/data/asset/assetList.json';

// ----------------------------------------------------------------

/** dataDelay simplement retrasa un temps la entrega per simular la lentitud
 * de un fetch
*/
const dataDelay = async (list) => {
  const timeout = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
  await timeout(5000);
  return list;
}

// ----------------------------------------------------------------

const loaderJSON = async () => {
  // console.log('*****-> loaderJSON -- ');
  const data = /* await */ Promise.all([
      dataDelay(assetList.filter((item) => item.type === 'property' && item.property.rental.rent === true)),
      dataDelay(assetList.filter((item) => item.type === 'property' && item.property.rental.long === true)),
      dataDelay(assetList.filter((item) => item.type === 'property' && item.property.rental.sale === true)),
      dataDelay(assetList.filter((item) => item.type === 'hotel')),
      dataDelay(assetList.filter((item) => item.type === 'real_state')),
      dataDelay(assetList.filter((item) => item.type === 'eat')),
      dataDelay(assetList.filter((item) => item.type === 'store')),
      dataDelay(assetList.filter((item) => item.type === 'activity')),
      dataDelay(assetList.filter((item) => item.type === 'service'))
  ]);
  // console.log('*****<- loaderJSON -- data --> ', data);
  return (data); 
};

// ****************************************************************
// END -- FOR DEVELOPMENT ONLY
// ****************************************************************

// ****************************************************************

const loader = async () => {
  // console.log('*****-> loader -- ');
  const data = /* await */ Promise.all([
      dataLoader({queryKey:['assets', 'rent'], queryFn: getAssetsRent, staleTime:60*60*1000}),
      dataLoader({queryKey:['assets', 'long'], queryFn: getAssetsLong, staleTime:61*60*1000}),
      dataLoader({queryKey:['assets', 'sale'], queryFn: getAssetsSale, staleTime:62*60*1000}),
      dataLoader({queryKey:['assets', 'hotel'], queryFn: getAssetsHotel, staleTime:63*60*1000}),
      dataLoader({queryKey:['assets', 'real_state'], queryFn: getAssetsRealState, staleTime:64*60*1000}),
      dataLoader({queryKey:['assets', 'eat'], queryFn: getAssetsRestaurant, staleTime:65*60*1000}),
      dataLoader({queryKey:['assets', 'store'], queryFn: getAssetsStore, staleTime:66*60*1000}),
      dataLoader({queryKey:['assets', 'activity'], queryFn: getAssetsActivity, staleTime:67*60*1000}),
      dataLoader({queryKey:['assets', 'service'], queryFn: getAssetsService, staleTime:68*60*1000})
  ]);

  // RETURN PROMISES -- no await
  // const [assetsRent] = Promise.all([
  //     fetchAssetsRent()
  //   ]);
  // const section3 = fetchSection3();
  
  /* console.log('loader result --> ', data  ); */

  return (data); 
};

// ****************************************************************

const parallelDataLoader = async () => {
  // console.log('*****-> parallelDataLoader -- ');

  // console.log('PropertyRentLoader -- ENVIRONMENT --', import.meta.env.MODE);
  // console.log('*****-> PropertyRentLoader -- filter -> ', filter);
  // console.log('*****-> PropertyRentLoader -- apiUrl --> ', apiUrl);

  let data  = [];

  if(import.meta.env.MODE === 'development') {
    // console.log('parallelDataLoader -- ENVIRONMENT -->', import.meta.env.MODE);
    data = await loaderJSON();
  } else { data = await loader()}

  // console.log('*****<- parallelDataLoader -- result --> ', data );

  return defer(
/*     new Promise((resolve, reject) => {
        resolve( */
        { 
          assetsRent: data[0],
          assetsLong: data[1],
          assetsSale: data[2],
          assetsHotel: data[3],
          assetsRealState: data[4],
          assetsRestaurant: data[5],
          assetsStore: data[6],
          assetsActivity: data[7],
          assetsService: data[8]
        }
    /*  );
    })*/
   );
};

export default parallelDataLoader;
