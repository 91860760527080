// propertiesLoader component

// import {defer} from "react-router-dom";
import { QueryClient } from '@tanstack/react-query';

// ****************************************************************

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10, // 10 segundos // (ms) stale time = temps que es mantindran les dades en cache
    },
  },
});

// ****************************************************************
// dataLoader --> usa react query el que permet carregar les dades en cache
// les dades queden identificades per queryKey
// queryFn --> funcio que retorna les dades --> fetch
// ****************************************************************
export async function dataLoader(params) {
  // console.log('*****-> dataLoader -- ', params.queryKey, ' -- params --> ', params);

  const queryKey = params.queryKey;
  const queryFn = params.queryFn;
  const staleTime = params.staleTime;

  let data;

  //const queryFn = query('assets');

  try {
    data = await queryClient.fetchQuery({
      queryKey,
      queryFn,
      staleTime // ms stale time = temps que es mantindran les dades en cache
    });
  } catch (error) {
    console.log('*** ERROR *** -- dataLoader -- error fetching data --> ', error);
  }

  // console.log('*****<- dataLoader -- ', params.queryKey, ' -- result --> ', data );
  /* return defer({
    dataList
  }); */
  return (
    data
  ); 
}
