
import dayjs from "dayjs";
import { defer } from "react-router-dom";
import { dataLoader } from '/src/data/dataLoader/dataLoader';
import { getAssetDesc } from '/src/data/api/assetDesc';
import { getAssetAvailability } from '/src/data/api/assetAvailability';

// ****************************************************************
// FOR DEVELOPMENT ONLY --> LOAD DATA FROM MOCKS --> FOR DEVELOPMENT ONLY
// ****************************************************************
import assetDescList from '/src/mocks/data/asset/assetDescList.json';
import assetSeasonList from '/src/mocks/data/asset/assetSeasonList.json';

// ----------------------------------------------------------------

/** dataDelay simplement retrasa un temps la entrega per simular la lentitud
* de un fetch
*/
const dataDelay = async (list) => {
    const timeout = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
    await timeout(2000);
    return list;
  }

const loaderJSONdesc = async (params) => {
    const { id, lang } = params;
    //console.log('*****-> loaderJSON -- params: ', params);
    const data = await dataDelay(
            assetDescList.filter((item) =>
              item.asset_id.$oid === id &&
              item.lang === lang )
        );
    return (data); 
  };

const loaderJSONseason = async (params) => {
  const { id } = params;
  // console.log('*****-> loaderJSON -- params: ', params);
  const data = await dataDelay(
      assetSeasonList.filter((item) =>
            item.asset_id.$oid === id )
      );
  // console.log('*****<- loaderJSON -- data: ', data);
  return (data); 
};

// ----------------------------------------------------------------

// procesar json per que retorni un event per dia
const processSeason = (data) => {
  // console.log('*****<- processSeason -- data --> ', data);
  let result = [];
  let dataInterval = 0; // per no pasar la revisio de un sol dia si nomes hi ha un interval de dades
  const now = dayjs().hour(0).minute(1);
    if (Array.isArray(data) && data.length > 0) {
    data.forEach( item => {
      if (item.type === 'free') {
      const start = dayjs(item.start);
      const end = dayjs(item.end).valueOf() < dayjs(now).add(1, 'year').valueOf() ?
                    dayjs(item.end) : dayjs(now).add(1, 'year');
      // recorre el interval de dates 
      for ( let day = start; day.isBefore(end); day = day.add(1, 'day') ) {
        if (dayjs(day).isAfter(now) ){
          const dayJSformat = new Date(dayjs(day).set('hour', 0).set('minute', 0).set('second', 0).set('milisecond', 0));
          const dayData = {
            "id": item.asset_id+'-'+day,
            "type": "free",
            "start": dayJSformat,
            "end": dayJSformat,
            "month": dayjs(day).month(),
            "duration": 1,
            "allDay": true,
            "checkInHour": item.checkInHour,
            "checkOutHour": item.checkOutHour,
            "minDays": item.minDays,
            "startDay": item.startDay,
            "endDay": item.endDay,
            "price": item.price,
            "asset_id": item.asset_id,
            "updatedAt": item.updatedAt
          }
          // si no es la primera pasada cal mirar si la data ya esta
          if ( dataInterval ){ 
            // filtrar perque un dia nomes pot tenir un event, 
            // el mes nou --> updatedAt
            let insert = false;
            let long = result.length;       
                for (let i = 0; i < long && !insert ; i++) {
                  if ( dayjs(result[i].start).unix() > (dayjs(dayData.start)).unix() ) {
                    result.splice(
                      i, // {índice por dónde empezar},
                      0, // {cuántos elementos para eliminar},
                      dayData // {elementos para agregar}
                    );
                    insert = true;
                  }
                  else if (dayjs(result[i].start).unix() === (dayjs(dayData.start)).unix()) {
                    if ( dayjs(result[i].updatedAt).unix() < (dayjs(dayData.updatedAt)).unix() ) {
                      result.splice(i,1,dayData);
                    }
                    insert = true;
                  }
                }
                if (!insert) result.push(dayData);
          }
          else result.push(dayData); // si primera pasada el posa directament
        }
      }
      dataInterval++;
      }
    });
  }

  // console.log('*****<- processSeason -- result --> ', result);
  return (result);
}

// ----------------------------------------------------------------

export default async function assetDataLoader (params) {
  const {id, type} = params.params;
  // console.log('*****-> assetDataLoader -- params: ', params);

  let assetDesc = [];
  let seasonLoaded = [];
  let season = [];
  const lang = localStorage.getItem("lang");

  if(import.meta.env.MODE === 'development') {
    assetDesc = await loaderJSONdesc({ id, lang});
    seasonLoaded = await loaderJSONseason({ id });
    season = processSeason(seasonLoaded);
  } else { 
    assetDesc = await dataLoader({
        queryKey:['assetsDesc', id, lang], 
        queryFn: async () => {
          const data = await getAssetDesc({ id, lang })
          return data
        }, 
        staleTime:60*60*1000}); // 1h
    
    if (type === 'rent') {
      seasonLoaded = await dataLoader({
        queryKey:['assetAvailability', id], 
        queryFn: async () => {
          const data = await getAssetAvailability({ id })
          return data
        },
        staleTime:1000}); // 1 seg 
      // console.log('assetDataLoader -- seasonLoaded --> ', seasonLoaded);
      season = processSeason(seasonLoaded);
    }
  }

  // console.log('*****<- assetDataLoader -- assetDesc, season : ', assetDesc, season );
  return defer ( { assetDesc, season } );
}
