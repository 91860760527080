// import { React, useState }  from 'react';
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { Outlet, useNavigation, } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
// import './App.css'

import { ThemeProvider } from "@mui/material";
import { useThemeStore } from '/src/store/theme';
import { light, dark } from '/src/theme/themes.jsx';

import setInitialValues from "/src/services/v1/pen/setInitialValues.js";
// import config from "./config/config.json";

function App() {
  // console.log('*****-> App');
  /// ***************************************************************************
  /// Limpia la consola cada reinici
  /// ***************************************************************************
  // console.clear(); 
  
  const initializeTheme = useThemeStore(state => state.initializeTheme);
  const themeStore = useThemeStore(state => state.theme);
  const navigation = useNavigation();

  // VARIABLES
  // ****************************************************************
  // set default values
  // const { intialTheme, lang } = setInitialValues();
  useEffect(()=>{
    setInitialValues();
    initializeTheme();
  }, []);
  // ****************************************************************

  // console.log('*****<- App');
  return (
    <>
    <main className='root' id='root' >

      {/* **************************************************************************
      /// permet mostrar un missage mentres es carga la pagina
      /// ************************************************************************* */}
      <div
        id="detail"
        className={navigation.state === "loading" ? "loading" : ""}
      >
        <ThemeProvider theme={themeStore==='light'?light:dark}>
            <Outlet />
        </ThemeProvider>

        <ToastContainer 
          position="bottom-right" // "top-left" || "bottom-right"
          transition={Slide}
          autoClose={10000}
          limit={15}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={themeStore}
        />
      </div>

    </main>
    </>
  )
}

export default App
