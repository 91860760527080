
import { defer } from "react-router-dom";
import { dataLoader } from '/src/data/dataLoader/dataLoader';

import { getmapMarkers } from '/src/data/api/mapMarkers';

// ****************************************************************
// FOR DEVELOPMENT ONLY --> LOAD DATA FROM MOCKS --> FOR DEVELOPMENT ONLY
// ****************************************************************
import mapMarkers from '/src/mocks/data/map/mapMarkers.json';

// ----------------------------------------------------------------

/** dataDelay simplement retrasa un temps la entrega per simular la lentitud
* de un fetch
*/
const dataDelay = async (list) => {
    const timeout = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
    await timeout(2000);
    return list;
  }

const loaderJSON = async (params) => {
    //console.log('*****-> loaderJSON -- params: ', params);
    const data = await dataDelay( mapMarkers );
    return (data); 
  };

// ----------------------------------------------------------------

export default async function mapMarkersDataLoader (params) {
  //console.log('*****-> mapMarkersDataLoader -- params: ', params);

  let data = [];

  if(import.meta.env.MODE === 'development') {
    data = await loaderJSON();
  } else { 
    data = await dataLoader({
        queryKey:['mapMarkers'], 
        queryFn: getmapMarkers, 
        staleTime:60*60*1000});
  }

  // console.log('*****<- mapMarkersDataLoader -- result: ', data);
  return defer ( {mapMarkers: data} );
}
